import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ErrorToastService, ErrorContentDto } from '@uoa/error-pages';

@Injectable({
  providedIn: 'root',
})
export class AppToastService implements ErrorToastService {
  constructor(private _toastCtrl: ToastController) {}

  async createToast(content: ErrorContentDto) {
    const toast = await this._toastCtrl.create({
      message: content.content,
      header: content.title,
      position: 'middle',
      color: 'dark',

      buttons: [
        {
          text: 'Reload',
          role: 'cancel',
          handler: () => window.location.reload(),
        },
      ],
    });
    toast.present();
  }
}
